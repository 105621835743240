import React from "react"
import styled from "styled-components"
import {
  TextareaAutosize,
  Container,
  Stack,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core"
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa"

const Contact = () => {
  const matches = useMediaQuery("(max-width: 425px)")
  return (
    <Wrapper>
      <Container className="container" maxWidth={"xl"}>
        <br />
        <div className={"title"}>Contact Us</div>

        <Grid
          container
          direction={"row"}
          justifyContent={"space-evently"}
          style={{ width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <form
              method="POST"
              style={{ flex: "1", marginBottom: "40px" }}
              name="Contact Form"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <Grid
                className={"fields-wrapper"}
                container
                direction={"row"}
                spacing={3}
              >
                <Grid item xs={12} md={6}>
                  <input placeholder={"Name"} type="text" name="name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <input placeholder={"Email"} type="email" name="email" />
                </Grid>
              </Grid>

              <br />

              <Stack
                direction={"row"}
                className={"textarea-wrapper"}
                spacing={3}
              >
                <TextareaAutosize
                  className={"textarea"}
                  minRows={3}
                  name="comments"
                  placeholder="Your Comments"
                />
              </Stack>

              <br />
              <Button variant={"outlined"} type="submit" className={"btn"}>
                Send message
              </Button>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              style={{ flex: "1", marginTop: "20px" }}
              alignItems={matches ? "flex-start" : "flex-end"}
              justifyContent={"flex-end"}
            >
              <div
                style={{
                  color: "white",
                  fontFamily: "Poppins",
                  letterSpacing: "0.8px",
                  fontSize: "26px",
                }}
              >
                Cofiato Inc.
              </div>
              <br />

              <Stack
                direction={"row"}
                spacing={2}
                style={{ color: "white", fontSize: "20px" }}
              >
                <a
                  style={{ color: "white", fontSize: "20px" }}
                  href={"https://in.linkedin.com/company/cofiato"}
                >
                  <FaLinkedinIn />
                </a>
                <a
                  style={{ color: "white", fontSize: "20px" }}
                  href={"mailto:info@cofiato.com"}
                >
                  <FaEnvelope />
                </a>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction={"row-reverse"}
          style={{ marginTop: "15px" }}
          spacing={matches ? 1 : 5}
        >
          <div
            style={{
              fontFamily: "Poppins",
              color: "rgba(256,256,256,0.7)",
              flex: 1,
            }}
          >
            © 2021 - Cofiato Inc Pvt. Ltd.
          </div>
          {/* <div
            style={{
              fontFamily: "Poppins",
              color: "rgba(256,256,256,0.7)",
            }}
          >
            Careers
          </div> */}
          {/* <div
            style={{
              fontFamily: "Poppins",
              color: "rgba(256,256,256,0.7)",
            }}
          >
            About
          </div> */}
        </Stack>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 40px 0;

  overflow: hidden;
  background: linear-gradient(108.59deg, #f2c775 0%, #db5892 62.58%);

  @media (max-width: 1440px) {
    padding: 50px 5%;
  }

  @media (max-width: 425px) {
    padding: 50px 20px;
  }

  & .title {
    font-size: 28px;
    text-align: left;
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0.65px;
    color: #fff;
    margin-bottom: 80px;
  }

  input,
  .textarea {
    outline: none;
    background: rgba(256, 256, 256, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: black;
    flex: 1;
    padding: 10px;
    font-size: 14px;
    resize: none;
    width: 280px;
    font-family: "Poppins";
  }
  .textarea-wrapper {
    width: 80%;
  }
  .fields-wrapper {
    width: 85%;
  }
  @media (max-width: 425px) {
    .textarea,
    input,
    textarea {
      width: 100%;
    }
    .container {
      padding: 0;
    }
    .textarea-wrapper {
      width: 94%;
    }
    .fields-wrapper {
      width: 100%;
    }
  }
  .btn,
  .btn:hover {
    background: #db5892;
    border-radius: 0;
    border: none;
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
  }
`

export default Contact
